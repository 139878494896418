// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-article-tsx": () => import("./../../../src/components/templates/Article.tsx" /* webpackChunkName: "component---src-components-templates-article-tsx" */),
  "component---src-pages-archives-index-tsx": () => import("./../../../src/pages/archives/index.tsx" /* webpackChunkName: "component---src-pages-archives-index-tsx" */),
  "component---src-pages-biz-index-tsx": () => import("./../../../src/pages/biz/index.tsx" /* webpackChunkName: "component---src-pages-biz-index-tsx" */),
  "component---src-pages-company-index-tsx": () => import("./../../../src/pages/company/index.tsx" /* webpackChunkName: "component---src-pages-company-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partner-index-tsx": () => import("./../../../src/pages/partner/index.tsx" /* webpackChunkName: "component---src-pages-partner-index-tsx" */)
}

